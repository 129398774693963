import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "../../pages/NotFound";
import Login from "../../pages/Login";
import AccessRecovery from "../../pages/AccessRecovery";

const PublicRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/login/*" element={<Login />} />
      <Route path="/login/recovery" element={<AccessRecovery />} />
      <Route path="/error/404" element={<NotFound />} />
    </Routes>
  );
};

export default PublicRoutes;
