import { FC } from "react";

const AccessRecovery: FC = () => {
  return (
    <div>
      <h1>Access recovery page</h1>
    </div>
  );
};

export default AccessRecovery;
