import { FC } from "react";

const Dashboard: FC = () => {
  return (
    <div>
      <h1>Dashboard page</h1>
    </div>
  );
};

export default Dashboard;
