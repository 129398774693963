import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PrivateRoutes from "./router/Private";
import PublicRoutes from "./router/Public";
import Store from "./store";

function App() {
  const store = Store.useContainer();
  const navigate = useNavigate();

  axios.interceptors.request.use((config) => {
    if (store.auth.token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${store.auth.token}`;
    }

    return config;
  });

  useEffect(() => {
    if (!store.auth.isAuth) {
      navigate("/login");
    }
  }, [store.auth.isAuth]);

  return store.auth.isAuth ? <PrivateRoutes /> : <PublicRoutes />;
}

export default App;
