import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../../pages/Dashboard";
import Error from "../../pages/Error";
import NotFound from "../../pages/NotFound";

const PrivateRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/error/*" element={<Error />} />
      <Route path="/error/404" element={<NotFound />} />
    </Routes>
  );
};

export default PrivateRoutes;
