import { Dispatch, RefObject, SetStateAction, useEffect, useState } from "react";

const LS = window.localStorage;

export const usePersistentState = <S>(initialState: S | (() => S), key: string): [S, Dispatch<SetStateAction<S>>] => {
  const LSValue = LS.getItem(key);
  const [value, setValue] = useState<S>(LSValue ? (JSON.parse(LSValue) as S) : initialState);

  useEffect(() => {
    LS.setItem(key, JSON.stringify(value));
  }, [value]);

  return [value, setValue];
};

export const onClickOutside = (ref: RefObject<HTMLElement> | RefObject<HTMLElement>[], handler: (e: Event) => void) => {
  useEffect(() => {
    const listener = (e: Event) => {
      if (Array.isArray(ref)) {
        let contains: boolean | undefined = false;

        for (let i = 0; i < ref.length; i += 1) {
          contains = !ref[i].current || ref[i].current?.contains(e.target as Node);
        }

        if (contains) return;

        handler(e);
      } else {
        if (!ref.current || ref.current.contains(e.target as Node)) {
          return;
        }

        handler(e);
      }
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};
