import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "normalize.css";
import "beeds/dist/assets/css/main.css";
import "./assets/scss/main.scss";
import Store from "./store";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <BrowserRouter>
    <Store.Provider>
      <App />
    </Store.Provider>
  </BrowserRouter>,
);
