import { FC } from "react";
import cn from "classnames";
import { Button, ButtonGroup } from "beeonis-ui-kit";

const Login: FC = () => {
  return (
    <div className={cn("b-page")}>
      <h1>Student Login page</h1>
      <Button text="test" />
      <ButtonGroup>
        <>
          <Button text="test-1" radius={false} />
          <Button text="test-2" radius={false} />
        </>
      </ButtonGroup>
    </div>
  );
};

export default Login;
